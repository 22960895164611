import React, { useEffect, useState } from "react"
import { interactionResponse } from "./defer"

interface Props {
  shouldShow: boolean
  children: React.ReactNode
}

const DeferRender: React.FC<Props> = ({ shouldShow, children }) => {
  const [isReady, setIsReady] = useState(shouldShow)

  useEffect(() => {
    const changeState = async (state: boolean) => {
      await interactionResponse()
      setIsReady(state)
    }

    changeState(shouldShow)
  }, [shouldShow])

  if (!isReady) {
    return null
  }

  return <>{children}</>
}

export default DeferRender
